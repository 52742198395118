<template>
  <ui-dialog title="Neue Veranstaltung" ref="dialog" @confirm="confirm()" size="wide" confirmLabel="Erstellen" :valid="valid">
    <ui-field label="Veranstaltungsname">
      <ui-form-input v-model="event.name"/>
    </ui-field>
    <ui-field label="Start">
      <ui-form-datepicker v-model="event.startDate" />
    </ui-field>
    <ui-field label="mehrtägig?">
      <ui-form-switch v-model="multiDay" />
    </ui-field>
    <ui-field label="Ende" v-if="multiDay">
      <ui-form-datepicker v-model="event.endDate"/>
    </ui-field>
  </ui-dialog>
</template>

<script>
import {event} from "@/store/controllers/event";

export default {
  name: "NewEvent",
  data(){
    return {
      multiDay: false,
      event: {
      },
      valid: () => {
        let valid = true
        if(this.event.name.length === 0) valid = false
        if(this.event.startDate.length === 0) valid = false
        if(this.event.endDate.length === 0 && this.multiDay === true) valid = false
        return valid
      }
    }
  },
  mounted(){
    this.event = Object.assign(this.event, event)
  },
  computed: {
  },
  methods: {
    async confirm(){
      if(this.event.startDate.length < 3) return false
      this.event.status = 'ACTIVE'
      if (this.multiDay === false) this.event.endDate = this.event.startDate
      this.event.slug = this.$store.sluggify(this.event.name)
      const newId = await this.$store.createEvent(this.event)
      if(newId !== null) this.$router.push({ name: 'AdminEventDashboard', params: { id: newId } })
    },
    open(){
      this.$refs.dialog.open()
    }
  }
}
</script>

<style scoped>

</style>