<template>
  <div class="flex">
    <!-- Backdrop -->
    <div
        :class="$env.sidebarIsOpen ? 'block' : 'hidden'"
        @click="$env.sidebarIsOpen = false"
        class="fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"
    ></div>
    <!-- End Backdrop -->

    <div
        :class="$env.sidebarIsOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'"
        class="fixed z-30 inset-y-0 left-0 w-64 transition duration-300 transform bg-gray-400 overflow-y-auto lg:translate-x-0 lg:static lg:inset-0"
    >
      <div class="flex items-center justify-center mt-4">
        <div class="flex items-center justify-center">
          <img src="/img/sfp-logo-demo.png" alt="" class="w-10/12">
        </div>
      </div>

      <nav class="mt-10" v-if="$route.meta.sidebar === 'event'">

        <Link icon="fal fa-home" label="Dashboard" :route="'/admin/event/'+$route.params.id" route-name="AdminEventDashboard" />

        <Link icon="fal fa-calendar-check" label="Eigenschaften" :route="'/admin/event/'+$route.params.id+'/settings'" route-name="AdminEventSettings" />

        <Link icon="fal fa-envelope" label="Mailings" :route="'/admin/event/'+$route.params.id+'/mailings'" route-name="AdminEventMailings" />

        <Link icon="fal fa-desktop" label="Landingpage" :route="'/admin/event/'+$route.params.id+'/landingpage'" route-name="AdminEventLandingpage" />

        <Link icon="fal fa-users" label="Teilnehmer" :route="'/admin/event/'+$route.params.id+'/attendees'" route-name="AdminEventAttendees" />

      </nav>

      <nav class="mt-10" v-if="$route.meta.sidebar === 'events'">

        <Link icon="fal fa-plus" label="Neue Veranstaltung" :action="() => $refs.newEvent.open()" />

        <Link icon="fal fa-calendar-day" label="Veranstaltungen" :route="'/admin/events'" route-name="AdminEvents" />

        <Link icon="fal fa-archive" label="Archiv" :route="'/admin/events/archive'" route-name="AdminEventsType" />

      </nav>
    </div>
    <new-event ref="newEvent" />
  </div>
</template>

<script>

import Link from "@/components/Admin/Sidebar/Link";
import NewEvent from "@/components/Admin/dialogs/NewEvent";

export default {
  name: 'Sidebar',
  components:{
    Link,
    NewEvent
  },
  data() {
    return {
      activeClass: "bg-gray-600 bg-opacity-25 text-gray-100 border-gray-100",
      inactiveClass: "border-gray-900 text-gray-500 hover:bg-gray-600 hover:bg-opacity-25 hover:text-gray-100",
      menuItems: [
        {}
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-red{
  background-color: #f00;
}
</style>
