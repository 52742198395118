<template>
  <router-link
      class="flex items-center mt-0 py-4 px-6 border-l-0"
      :class="[$route.name === routeName ? activeClass : inactiveClass]"
      :to="route"
      v-if="typeof route !== 'undefined'">
    <div class="icon">
      <i :class="icon"></i>
    </div>
    <span class="mx-4">
      {{ label }}
    </span>
  </router-link>
  <a class="flex items-center mt-4 py-2 px-6 border-l-4 cursor-pointer"
      :class="inactiveClass"
      @click="action()"
      v-if="typeof action !== 'undefined'">
    <i :class="icon"></i>
    <span class="mx-4">
      {{ label }}
    </span>
  </a>
</template>

<script>
export default {
  props: {
    icon: String,
    route: String,
    routeName: String,
    label: String,
    action: Function
  },
  data() {
    return {
      activeClass: "bg-gray-900 bg-opacity-75 text-gray-100 border-gray-100",
      inactiveClass: "border-gray-500 text-gray-900 hover:bg-gray-500 hover:bg-opacity-50"
    }
  }
};
</script>

<style scoped>
.icon {
  width: 22px;
  flex-grow: 0;
  flex-shrink: 0;
  overflow:hidden;
}
</style>
